import React, { useEffect, useState } from 'react';
import MarkerWithInfoWindow from "../components/MarkerWithInfoWindow.js"
import { Map } from "@vis.gl/react-google-maps";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { Helmet } from 'react-helmet-async';


const Contact = (props) => {
  const [device, setDevice] = useState(props.props)
  const [loading, setLoading] = useState(true);
  const [cookies, setCookies] = useState();
  const [pointerEvents, setPointerEvents] = useState("none")

  console.log(props.cookie)

  useEffect(() => {
    setLoading(true)
    setDevice(props.device)
    if (props.cookie !== undefined) {
      // console.log(props.cookies.categories.length)

      // when number of categories is 3 --> Allow Google Maps
      // when number of categories is 2 --> Check if Google Maps or Performance is Enabled --> Allow or Disallow Google Maps
      //  when number of categories is 1 --> Disallow Google Maps 
      if (props.cookie.categories.length === 3) {
        setCookies(2)
      } else if (props.cookie.categories.length === 2) {
        if (props.cookie.categories.includes("thirdparty")) {
          setCookies(2)
        } else {
          setCookies(1)
        }
      } else if (props.cookie.categories.length === 1) {
        setCookies(1)
      }
    }

    if (document.cookie !== "") {
      setPointerEvents(true)
    } else {
      setPointerEvents(false)
    }

    setLoading(false)
  }, [props])

  function showConsentModal() {
    CookieConsent.show(true);
  }
  if(loading) {
    return <div className="Loading">Loading...</div>;
  } else {
  return (
    <div className="page-container" style={ device === "mobile" ? {"fontSize":"12pt"} : {} || device === "tablet" ? {"fontSize":"14pt"} : {} }>
      <Helmet>
        <title>
          Kontaktdaten vom Autohaus Kauschinger
        </title>
        <meta name="description" content="Hier finden Sie die Kontaktdaten vom Autohaus Kauschinger."/>
      </Helmet>
      <div className="page-container-2" style={pointerEvents ? {pointerEvents: "auto"} : {pointerEvents: "none"}}>
        <h1 className="page-heading" style={ device === "mobile" ? {"fontSize":"20px"} : {} || device === "tablet" ? {"fontSize":"22pt"} : {} }>
          Kontakt
        </h1> 
          {
            (() => {
              if(device === "mobile" || device === "tablet") {
                // console.log("is mobile")
                return (
                  <>
                    <div className="contact-flex-container-half-right">
                      <div className="contact-content-block">
                      <div className="contact-content-block-heading">Händlerangaben</div>
                        Inhaber: Christoph Kauschinger<br></br>
                        Email: <a href="mailto:info@autohaus-kauschinger.de?subject=Anfrage an das Autohaus Kauschinger">info@autohaus-kauschinger.de </a>  <br></br>
                        Telefon: <a href="tel:+4915165874553">+49 (0) 151 65874553</a><br></br>
                      </div>
                      <div className="contact-content-block" style={{marginTop:"35px"}}>
                        <div className="contact-content-block-heading">Anfahrt</div>
                        Autohaus Kauschinger <br></br>
                        Hauptstraße 18 <br></br>
                        94469 Deggendorf <br></br>
                        Deutschland <br></br>
        
                        <div className="contact-content-block-heading" style={{marginTop:"35px", marginBottom:"35px", fontWeight:"normal", textAlign:"center"}}>                
                          Bitte beachten Sie, dass wir keine festen Bürozeiten haben. Vereinbaren Sie daher bitte vorab telefonisch einen Termin.
                          Wir freuen uns auf Ihren Besuch.
                        </div>
                    </div>
                  </div>
                    <div className="contact-flex-container-half" style={ device === "mobile" ? {height: '50vh', width: '100%' } : {height: '50vh', width: '100%' }}>
                      { cookies === 1 || cookies === undefined ? 
                        <div className="contact-google-maps-replacement-container-mobile">
                          <div className="contact-google-maps-replacement-container-inner-mobile">
                            Google Maps wurde aufgrund Ihrere Cookie-Präferenzen nicht geladen. Sollten Sie Google Maps doch einbinden wollen, dann können Sie Ihre Cookieeinstellungen hier widerrufen:
                            <div>
                              <button className="customCookieButton" type="button" data-cc="show-consentModal" style={{marginTop:"20px"}} onClick={showConsentModal}>Cookie Präferenzen</button>
                            </div>
                          </div>
                        </div> 
                      : <Map
                        defaultZoom={12.5}
                        defaultCenter= {{ lat: 48.84228517751929, lng: 12.956001177545067 }}
                        mapId="368ce9b63e079c08"
                      >
                        <MarkerWithInfoWindow />
                      </Map>
                      }
                    </div>
                  </>
                )
              } else {
                // console.log("is desktop")
                return(
                  <div className="contact-parent-flex-container">
                  <div className="contact-flex-container-half" style={ device === "mobile" ? {height: '50vh', width: '100%' } : {height: '50vh', width: '100%' }}>
                  { cookies === 1 || cookies === undefined ? 
                    <div className="contact-google-maps-replacement-container">
                      <div className="contact-google-maps-replacement-container-inner">
                        Google Maps wurde aufgrund Ihrere Cookie-Präferenzen nicht geladen. Sollten Sie Google Maps doch einbinden wollen, dann können Sie Ihre Cookieeinstellungen hier widerrufen:
                        <div>
                          <button className="customCookieButton" type="button" data-cc="show-consentModal" style={{marginTop:"20px"}} onClick={showConsentModal}>Cookie Präferenzen</button>
                        </div>
                      </div>
                    </div> 
                  :<Map
                    defaultZoom={12.5}
                    defaultCenter= {{ lat: 48.84228517751929, lng: 12.956001177545067 }}
                    mapId="368ce9b63e079c08"
                  >
                    <MarkerWithInfoWindow />
                  </Map>
                  }

                  </div>
        
                  <div className="contact-flex-container-half-right">
                    <div style={{marginLeft: "25px", marginTop: "25px"}}>
                      <div className="contact-content-block">
                      <div className="contact-content-block-heading">Händlerangaben</div>
                        Inhaber: Christoph Kauschinger<br></br>
                        Email: <a href="mailto:info@autohaus-kauschinger.de?subject=Anfrage an das Autohaus Kauschinger">info@autohaus-kauschinger.de </a>  <br></br>
                        Telefon: <a href="tel:+4915165874553">+49 (0) 151 65874553</a><br></br>
                      </div>
                      <div className="contact-content-block" style={{marginTop:"35px"}}>
                        <div className="contact-content-block-heading">Anfahrt</div>
                        Autohaus Kauschinger <br></br>
                        Hauptstraße 18 <br></br>
                        94469 Deggendorf <br></br>
                        Deutschland <br></br>
        
                        <div className="contact-content-block-heading" style={{marginTop:"35px", fontWeight:"normal"}}>                
                          Bitte beachten Sie, dass wir keine festen Bürozeiten haben. Vereinbaren Sie daher bitte vorab telefonisch einen Termin.
                          Wir freuen uns auf Ihren Besuch.
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
                )
              }
            })()
          }   
      </div>
    </div>
  );
};
};

  
export default Contact;