import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import {APIProvider} from '@vis.gl/react-google-maps';
import Header from './components/Header.js';
import Footer from './components/NavFooter.js';
import NavBar from './components/NavBar.js';
import About from './pages/About.js';
import Home from './pages/Home.js';
import Vehicles from './pages/Vehicles.js';
import VehicleDetails from './pages/VehicleDetails.js';
import Reviews from './pages/Reviews.js';
import Kontakt from './pages/Contact.js';
import ReviewsFooter from './components/FooterReviews.js';
import My404Component from './pages/My404Component.js';
import Cookies from './pages/Cookies.js';
import Datenschutz from './pages/Datenschutz.js';
import Impressum from './pages/Impressum.js';
import Ankauf from './pages/Ankauf.js';
import useMediaQuery from './components/MediaQuery.js';
import { isMobile } from 'react-device-detect';
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from "react-ga4";


const googleMapsAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const urlBasePath= process.env.REACT_APP_URL_BASE_PATH
const googleAnalyticsTrackingID= process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID


function App() {

  // Comment: Enable debugging logs for search engine indexing issues
  // window.onerror = function (message, url, lineNo, colNo, error) {
  //   console.log(arguments);

  //   let container = document.createElement('div');

  //   container.style.color = 'red';
  //   container.style.position = 'fixed';
  //   container.style.background = '#eee';
  //   container.style.padding = '2em';
  //   container.style.top = '1em';
  //   container.style.left = '1em';

  //   let msg = document.createElement('pre');
  //   msg.innerText = [
  //       'Message: ' + message,
  //       'URL: ' + url,
  //       'Line: ' + lineNo,
  //       'Column: ' + colNo,
  //       'Stack: ' + (error && error.stack)
  //   ].join('\n');

  //   container.appendChild(msg);

  //   document.body.appendChild(container);
  // };


  // Google Analytics --> Page views are sent automatically
  // useEffect(() => {
  //   ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });
  //   console.log("send page view event to google analytics")
  //   console.log(window.location.pathname)
  // },)


  // Comment: Disable hovering style changes for mobile devices
  function hasTouch() {
    return 'ontouchstart' in document.documentElement
           || navigator.maxTouchPoints > 0
           || navigator.msMaxTouchPoints > 0;
  }
  
  if (hasTouch()) { // remove all the :hover stylesheets
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si];
        if (!styleSheet.rules) continue;
  
        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue;
  
          if (styleSheet.rules[ri].selectorText.match(':hover')) {
            styleSheet.deleteRule(ri);
          }
        }
      }
    } catch (ex) {}
  }

  // eslint-disable-next-line
  var [orientation, setOrientation] = useState({});
  var [loading, setLoading] = useState(true);
  var [device, setDevice] = useState(false);
  // eslint-disable-next-line
  var [isWideFooter, setIsWideFooter] = useState(false)
  var [isMobileDevice, setIsMobileDevice] = useState('')
  const [cookies, setCookies] = useState()
  const [opacity, setOpacity] = useState(1)

  var ls = useMediaQuery("(min-width: 1090px)")
  var ms = useMediaQuery("(min-width: 641px)")
  var wideFooter = useMediaQuery("(min-width: 800px)")
  var twoColumnLayout = useMediaQuery("(min-width: 950px)")
  var isPortrait = useMediaQuery("(orientation:portrait)")

  //reload page when document visibility changes from hidden to visible --> Bug on I
  // document.addEventListener( 'visibilitychange' , function() {
  //   if (document.hidden) {
  //   } else {
  //       window.location.reload();
  //   }
  // }, false );

  useEffect(() => {
    setLoading(true)
    if (window.screen.orientation !== undefined){
      setOrientation(window.screen.orientation.type)
    } 
    setIsMobileDevice(isMobile)
    setLoading(false)
  }, [])

  useEffect(() => {
    setLoading(true)
    CookieConsent.run({
      hideFromBots: false,
      guiOptions: {
        consentModal: {
          layout: 'cloud inline',
          position: 'middle center',
          equalWeightButtons: true,
          flipButtons: false
        },
        preferencesModal: {
          layout: 'cloud inline',
          position: 'middle center',
          equalWeightButtons: true,
          flipButtons: false
        }
    },

    onConsent: ({cookie}) => {
      // console.log('onConsent fired',cookie);
      // window.location.reload();
      setCookies(cookie)
    },

    onChange: ({cookie, changedCategories, changedServices}) => {
      // console.log('onChange fired',cookie);
      window.location.reload();
      setCookies(cookie)
    },

    onModalShow: ({modalName}) => {
      document.body.style.overflow = "hidden";
      setOpacity(0.5)
    }, 

    onModalHide: ({modalName}) => {
      if (modalName === "consentModal") {
        document.body.style.overflow = "scroll";
        document.body.style.position = "relative";
        setOpacity(1) 
      } else if (modalName === "preferencesModal" && window.location.href !== urlBasePath + "/home") {
        document.body.style.overflow = "scroll";
        document.body.style.position = "relative";
        setOpacity(1) 
      }
      
    }, 

    sameSite:"Lax",

    categories: {
      necessary: {
        enabled: true,  // this category is enabled by default
        readOnly: true  // this category cannot be disabled
      },
      // analytics / performance cookies
      performance: {
        enabled: true,
        autoClear: {
          name: /^(_ga)/      //regex
        },
        reloadPage: true
      },
      // third-party services / google maps
      thirdparty: {
          enabled: true,
          autoClear: {
            name: /^(_ga)/      //regex
          },
          reloadPage: true
      }
    },
  
    language: {
        default: 'de',
        translations: {
            de: {
                consentModal: {
                    title: 'Unsere Website nutzt Cookies',
                    description: `Wir benötigen Ihre Zustimmung, bevor Sie unsere Website besuchen können. Wir verwenden Cookies und andere Technologien auf userer Website. Einige von ihnen sind essenziell, während andere uns helfen diese Website und ihre Interaktion mit dieser Website zu verbessern. Bei der Nutzung unserer Website können personenbezogene Daten können verarbeitet werden (z.B. IP-Adressen). Zum einen verwenden wir Google Analytics um Interaktionen mit unserer Website zu tracken. Zum anderen binden wir Services von Drittparteien ein, insbesondere z.B. Google Maps, damit wir Ihnen die bestmögliche User-Experience bieten können. Sowohl für das Performance Tracking unserer Website durch Google Analytics als auch für das einbinden von Services von Drittparteien, insbesondere Google Maps, benötigen wir Ihre Zustimmung. Weitere Informationen über die Verwendung Ihrer Daten finden Sie in unserer <a href="${urlBasePath}/datenschutz">Datenschutzerklärung</a>, unserer <a href="${urlBasePath}/cookies">Cookie-Policy</a>, sowie in unserem <a href="${urlBasePath}/impressum">Impressum</a>. Sie können Ihre Auswahl jederzeit auf dieser Seite widerrufen oder anpassen. Wenn Sie unter 16 Jahre alt sind und Ihre Zustimmung zu freiwilligen Diensten geben möchten, müssen Sie Ihre Erziehungsberechtigten um Erlaubnis bitten.`,                    
                    acceptAllBtn: 'Alle akzeptieren',
                    acceptNecessaryBtn: 'Essenzielle akzeptieren',
                    showPreferencesBtn: 'Individuelle Datenschutzeinstellungen'
                },
                preferencesModal: {
                    title: 'Individuelle Datenschutzeinstellungen',
                    acceptAllBtn: 'Alle akzeptieren',
                    acceptNecessaryBtn: 'Essenzielle akzeptieren',
                    savePreferencesBtn: 'Einstellungen speichern',
                    closeIconLabel: 'Close modal',
                    sections: [
                        {
                          description: 'Hier können Sie Ihre individuellen Datenschutzeinstellungen anpassen.'
                        },
                        {
                          title: 'Technisch notwendige Cookies',
                          description: 'Technisch notwendige Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich.',
                          //this field will generate a toggle linked to the 'necessary' category
                          linkedCategory: 'necessary'
                        },
                        {
                          title: 'Performance Cookies',
                          description: 'Performance Cookies sind standardmäßig blockiert. Um die Performance unserer Website zu verbessern, möchten wir die Nutzung unserer Website gerne mittles Google Analytics tracken und analyiseren. Performance Cookies und die dazugehörigen Dienste werden jedoch nur geladen und verwendet, wenn Sie die entsprechenden Performance Cookies akzepzieren. Andernfalls werden keine Performance Cookies angelegt und Ihre Interaktionen mit unserer Website werden weder aufgezeichnet noch analyisert.',
                          linkedCategory: 'performance'
                        },
                        {
                          title: 'Externe Services und Medien',
                          description: 'Inhalte von externen Services und Medien sind standardmäßig blockiert. Wir möchten auf unserer Website gerne Google Maps einbinden. Dieser Services wird jedoch erst für Sie freigeschaltet, wenn Sie Cookies für externe Services und Medien akzepziert werden. Andernfalls wird der Services nicht freigeschaltet und steht Ihnen somit auch nicht zur Verfügung.',
                          linkedCategory: 'thirdparty'
                        },
                        {
                          title: 'Weitere Informationen',
                          description: `Weitere Informationen zu unserer Cookie-Policy und ihrer Auswahl entnehmen Sie bitte unserer <a href="${urlBasePath}/cookies">Cookie-Seite</a>. Alternativ können Sie uns über die Kontaktdaten in unserem <a href="${urlBasePath}/impressum">Impressum</a> kontaktieren.`
                        }
                    ]
                }
              }
          }
      }
    });
    if (document.cookie === "") {
      CookieConsent.show(true);
    } 
    setLoading(false)
  }, []);

  useEffect(() => {
    if (window.screen.orientation === undefined) {
      document.addEventListener("orientationchange", updateOrientation);
      updateOrientation();
      return () => {
        document.removeEventListener("change", handleRotation);
      }

    } else {
      window.screen.orientation.addEventListener('change', handleRotation)
      window.screen.orientation.onchange = handleRotation();
      return () => {
        document.removeEventListener("change", handleRotation);
      }
    }

    // }
  }, [ls, ms, wideFooter, twoColumnLayout, isPortrait]);

  const handleRotation = () => {
    setOrientation(window.screen.orientation.type);
  }

  const updateOrientation = () => {
    setOrientation(window.orientation);
  }

  useEffect(() => {
    // console.log({ls, ms, wideFooter, twoColumnLayout, isPortrait})
    if (ls) {
      setDevice("desktop")
      setIsMobileDevice(isMobile)
      // console.log("Desktop device");
    } else if (ms) {
      setDevice("tablet")
      setIsMobileDevice(isMobile)
      // console.log("Tablet device");
    } else {
      setDevice("mobile")
      setIsMobileDevice(isMobile)
      // console.log("Mobile device");
    }
    setIsWideFooter(wideFooter)
  }, [ls, ms, wideFooter, isMobileDevice, twoColumnLayout, isPortrait])


  function renderRepeatedJSX(){
      return(
      <HelmetProvider>
        <div className={device === "mobile" || device === "tablet" ? "site-mobile" : "site-desk"} style={{opacity:opacity}}> 
          <Router>
          <div className={device === "mobile" || device === "tablet" ? "site-content-mobile" : "site-content-desk"}>
            <Header />
            <NavBar device={device} cookie={cookies}/> 
            { isMobile && !isPortrait ? 
              <div className="page-container" style={ device === "mobile" ? {"fontSize":"12pt"} : {} || device === "tablet" ? {"fontSize":"14pt"} : {} }>
                <div className="page-container-2">
                  <div className="page-heading" style={ device === "mobile" ? {"fontSize":"20px"} : {} || device === "tablet" ? {"fontSize":"22pt"} : {} }>
                    This page cannot be displayed in landscape mode on a mobile device. <br></br>Please rotate your device.
                  </div>
                </div>
              </div>
            : 
            <Routes>
              <Route path="/" element={<Navigate replace to="/home" />}/>
              <Route path='*' element={<My404Component device={device} cookie={cookies}/>} />
              <Route path='/home' element={<Home device={device} cookie={cookies}/>} />
              <Route path='/fahrzeuge' element={<Vehicles device={device} cookie={cookies}/>}/>
              <Route path='/fahrzeuge&page=:pageID' element={<Vehicles device={device} cookie={cookies}/>} />
              <Route path='/fahrzeuge/:FahrzeugID' element={<VehicleDetails device={device} cookie={cookies}/>} />
              <Route path='/bewertungen' element={<Reviews device={device} twoColumnLayout={twoColumnLayout} cookie={cookies}/>} />
              <Route path='/bewertungen&page=:pageID' element={<Reviews device={device} twoColumnLayout={twoColumnLayout} cookie={cookies}/>} />
              <Route path='/about' element={<About device={device} cookie={cookies}/>}/>
              <Route path='/ankauf' element={<Ankauf device={device} cookie={cookies}/>} />
              <Route path='/kontakt' element={<Kontakt device={device} cookie={cookies}/>} />     
              <Route path='/cookies' element={<Cookies device={device} cookie={cookies}/>} />
              <Route path='/datenschutz' element={<Datenschutz device={device} cookie={cookies}/>} />
              <Route path='/impressum' element={<Impressum device={device} cookie={cookies}/>} />
            </Routes>
          }
          </div>  
          <div className={device === "mobile" || device === "tablet" ? "footer-mobile" : "footer-desk"}> 
            <ReviewsFooter/>
            <Footer wideFooter={wideFooter} device={device}/>
          </div>
          </Router>
        </div>
      </HelmetProvider>
    )
  }



  if(loading) {
    return <div className="Loading">Loading...</div>;
  } 
  else if (cookies === undefined) {
    return (renderRepeatedJSX())
  } else if (cookies.categories.length === 1) {
    return (renderRepeatedJSX())
  } else if (cookies.categories.length === 2) {
    if (cookies.categories.includes("thirdparty")) {
      // console.log("Only third party")
      return (  
        <APIProvider apiKey={googleMapsAPIKey} 
          // onLoad={() => console.log('Googple Maps API has loaded.')}
        >
          {renderRepeatedJSX()}
        </APIProvider>
      )
    } else if (cookies.categories.includes("performance")) {
      // console.log("Only performance")
      ReactGA.initialize(googleAnalyticsTrackingID);
      return (renderRepeatedJSX())
    }
  } else if (cookies.categories.length === 3) {
    // console.log("all cookies accepted")
    ReactGA.initialize(googleAnalyticsTrackingID);
    return (  
      <APIProvider apiKey={googleMapsAPIKey} 
        // onLoad={() => console.log('Googple Maps API has loaded.')}
      >
        {renderRepeatedJSX()}
      </APIProvider>
    )
  }
  // else {
  //   return (
  //     <>
  //       { cookies.categories.length === 1 ? renderRepeatedJSX() 
  //         : 
  //         <APIProvider apiKey={googleMapsAPIKey} 
  //           // onLoad={() => console.log('Googple Maps API has loaded.')}
  //         >
  //           {renderRepeatedJSX()}
  //         </APIProvider>
  //       }
  //     </>
  //   );
  // }
}


export default App;
