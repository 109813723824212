import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

const Home = (props) => {
  var [device, setDevice] = useState(props.props)

  useEffect(() => {
    setDevice(props.device)
  }, [props])

  return (
    <div className="page-container" style={ device === "mobile" ? {"fontSize":"12pt"} : {} || device === "tablet" ? {"fontSize":"14pt"} : {} }>
      <Helmet>
        <title>
          Autohaus Kauschinger in Deggendorf
        </title>
        <meta name="description" content="Das Autohaus Kauschinger ist die erste Wahl für hochwertige, junge Gebrauchtfahrzeuge in Deggendorf. Alle unsere Fahrzeuge sind werkstattgeprüft." />
      </Helmet>
      <div className="page-container-2">
        <h1 className="page-heading" style={ device === "mobile" ? {"fontSize":"20px"} : {} || device === "tablet" ? {"fontSize":"22pt"} : {} }>
          Herzlich Willkommen im Autohaus Kauschinger!
        </h1>
        <div className='purchasing-content-block' style={ device === "mobile" || device === "tablet" ? {textAlign:"left"} : {textAlign:"justify"}}>
          Wir freuen uns, dass Sie zu uns gefunden haben. Im Autohaus Kauschinger finden Sie eine erstklassige Auswahl an hochwertigen, jungen Gebrauchtwagen in Deggendorf. Alle unsere Fahrzeuge sind werkstattgeprüft von zertifizierten Meisterwerkstätten.
        </div>
        <div className='purchasing-content-block' style={ device === "mobile"  || device === "tablet" ? {textAlign:"left"} : {textAlign:"justify"}}>
          Sie möchten finanzieren? Kein Problem! Mit unseren Finanzierungspartnern finden wir sicherliche die passende Finanzierungsrate für Sie. Bei uns finden auch Sie bestimmt Ihr nächstes Wunschfahrzeug.
        </div>
        <div className='vehicle-content-block' style={ device === "mobile"  || device === "tablet" ? {textAlign:"left", fontWeight:"bold"} : {textAlign:"justify", fontWeight:"bold"}}>
          Neben der klassischer Verkaufsberatung stehen wir Ihnen mit folgende Services zur Verfügung:
          <ul className='vehicle-services'>
            <li className='vehicle-services'>Abholung von Bahn und Flughafen.</li>
            <li className='vehicle-services'>110 Punktecheck bei Auslieferung.</li>
            <li className='vehicle-services'>Abholung von Bahn und Flughafen.</li>
            <li className='vehicle-services'> Inzahlungnahme Ihres alten Pkw.</li>
            <li className='vehicle-services'>An- und Abmeldung.</li>
            <li className='vehicle-services'>Gebrauchtwagengarantie gegen Aufpreis möglich.</li>
            <li className='vehicle-services'>TÜV/AU kann gegen geringen Aufpreis neu gemacht werden</li>
            <li className='vehicle-services'>Finanzierung auch ohne Anzahlung möglich.</li>
          </ul>
          <div className="vehicle-content-block" style={device === "mobile"  || device === "tablet" ? {fontWeight:"normal"} : {marginTop:"45px", marginBottom:"15px", fontWeight:"normal"}}>
          Wir freuen uns auf Ihren Besuch! 
          </div>
          <div className="vehicle-content-block" style={device === "mobile"  || device === "tablet" ? {fontWeight:"normal", marginBottom:"25px"} : {marginTop:"5px", marginBottom:"75px", fontWeight:"normal"}}>
          Christoph Kauschinger 
        </div>
        <div className='image-container' style={{marginBottom:"20px", textAlign:"center"}}> 
          <img src="/images/services.jpg" style={ device === "mobile"  || device === "tablet" ? {width:"100%"} : {width:"65%"}} alt='Angebotene Services vom Autohaus Kauschinger'/>
        </div>  
    </div>
        {/* import { InstagramEmbed } from 'react-social-media-embed'; */}
        {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
          <InstagramEmbed url="https://www.instagram.com/p/CsQ5kkzIyaX/" width={328} style={{"paddingRight":"15px"}}/>
          <InstagramEmbed url="https://www.instagram.com/reel/CsQ48dmI4_e/" width={328} style={{"paddingRight":"15px"}}/>
          <InstagramEmbed url="https://www.instagram.com/p/CsQ2Ap1Io8a/" width={328} style={{"paddingRight":"15px"}} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <InstagramEmbed url="https://www.instagram.com//reel/C8Ae1iiOFVj/" width={328} style={{"paddingRight":"15px"}}/>
          <InstagramEmbed url="https://www.instagram.com/p/C7ul1c2IgZk/" width={328} style={{"paddingRight":"15px"}}/>
          <InstagramEmbed url="https://www.instagram.com/reel/C7izDodIrr7" width={328} style={{"paddingRight":"15px"}} />
        </div> */}
      </div>
    </div>
  );  
};

export default Home;