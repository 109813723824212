import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
  
const My404Component = (props) => {
  const [device, setDevice] = useState(props.props)
  const [pointerEvents, setPointerEvents] = useState("none")

  useEffect(() => {
    setDevice(props.props)
    
    if (document.cookie !== "") {
      setPointerEvents(true)
    } else {
      setPointerEvents(false)
    }

  }, [props])

  return (
    <div className="page-container" style={ device === "mobile" ? {"fontSize":"12pt"} : {} || device === "tablet" ? {"fontSize":"14pt"} : {} }>
      <Helmet>
        <title>
        Die aufgerufene Seite exisitert nicht. 
        </title>
        <meta name="description" content="Die aufgerufene Seite exisitert nicht. "/>
      </Helmet>
      <div className="page-container-2" style={pointerEvents ? {pointerEvents: "auto"} : {pointerEvents: "none"}}>
        <h1 className="page-heading" style={ device === "mobile" ? {"fontSize":"20px"} : {} || device === "tablet" ? {"fontSize":"22pt"} : {} }>
          Die aufgerufene Seite exisitert nicht. 
        </h1>
        <div>
          Bitte klicken Sie auf folgenden Link um auf unsere Startseite zu gelangen: <Link to="/home"> Home </Link>
        </div>
      </div>
    </div>
  );
};
  
export default My404Component;