import React, { useEffect, useState } from 'react';


const VehiclelistContentline = (vehicles, scrollPosition) => {

  const [device, setDevice] = useState(vehicles.device)
  useEffect(() => {
      setDevice(vehicles.device)
  }, [vehicles])


  if (device === "mobile" || device === "tablet") {
    return (
      <div className="list-group-vehicle-item-content-line">
        <div className="list-group-vehicle-item-content-left-ragged-desk">
          <div className={"thumpnail-mobile"}>
            <img src={`${vehicles.props.objectURL}`} alt={`Thumpnail von ${vehicles.props.vehicleType}`} className={"thumpnail-mobile"}/>
            {/* <Thumpnail props={vehicles.props}/>  */}
          </div>
        </div>
        <div className="list-group-vehicle-item-content-right-ragged-mobile">
          <div className="list-group-vehicle-item-content-right-ragged-desk-flex-mobile">
            <div className="list-group-vehicle-item-content-element-flexible-mobile">
              <div className="list-group-vehicle-item-content-element-row-container-mobile">
                <div className="list-group-vehicle-item-content-element-row-mobile" style={{fontWeight:"bold"}}>
                  {vehicles.props.vehicleType}
                </div>
                <div className="list-group-vehicle-item-content-element-row-mobile">
                  {vehicles.props.ez}
                </div>
                <div className="list-group-vehicle-item-content-element-row-mobile">
                  {vehicles.props.km.toLocaleString('de')} KM
                </div>
                <div className="list-group-vehicle-item-content-element-row-mobile">
                  {vehicles.props.ps}
                </div>
              </div>
              {device === "tablet" ?
                <div className='list-group-vehicle-item-content-element-row-container-mobile'>  
                  <div className="list-group-vehicle-item-content-element-row-mobile"> 
                  </div> 
                  <div className="list-group-vehicle-item-content-element-row-mobile"> {vehicles.props.fuelType}
                  </div> 
                  <div className="list-group-vehicle-item-content-element-row-mobile"> {vehicles.props.gearBox}
                  </div> 
                  <div className="list-group-vehicle-item-content-element-row-mobile"> {vehicles.props.hu}
                  </div> 
                </div> 
              : <></> }
            </div>
            <div className="list-group-vehicle-item-content-element-rightaligned-mobile">
              {
                (() => {
                  if(vehicles.props.netGiven) {
                    return (
                      <div className="price-mobile">
                        { device === "mobile" ? 
                          //mobile design
                          <>
                            <div style={{fontWeight: 'bold'}}>{vehicles.props.grossPrice.toLocaleString("de-DE")} €<span style={{fontSize:"4pt"}}> (brutto)</span></div>
                            <div> {vehicles.props.netPrice.toLocaleString("de-DE")} €<span style={{fontSize:"4pt"}}> (netto)</span></div>
                            <div style={{fontSize:"8pt"}}> {vehicles.props.tax} </div> 
                          </>
                          :
                          //tablet design
                          <>
                            <div style={{fontWeight: 'bold'}}>{vehicles.props.grossPrice.toLocaleString("de-DE")} € (brutto) </div>
                            <div> {vehicles.props.netPrice.toLocaleString("de-DE")} € (netto)</div>
                            <div> {vehicles.props.tax} </div>
                          </>
                        }
                      </div>
                    )
                  } else {
                    return (
                      <div className="price-mobile" style={{fontSize:"10pt",fontWeight: 'bold'}}>
                        {vehicles.props.grossPrice.toLocaleString("de-DE")} €
                      </div>
                    )
                  }
                })()
              }
            </div>
          </div>
          <div className="list-group-vehicle-item-fuel-line-mobile">
            <div className="list-group-vehicle-item-fuel-line-right-mobile" style={{paddingRight:"5px"}}> <span className="NavigateToDetails" >ZU DEN DETAILS</span>   </div>
          </div>
        </div>
      </div>
  );
  } else {
    return (
      <div className="list-group-vehicle-item-content-line">
        <div className="list-group-vehicle-item-content-left-ragged-desk">
          <div className={"thumpnail-desktop"}>
            <img src={`${vehicles.props.objectURL}`} alt={`Thumpnail von ${vehicles.props.vehicleName}`} className={"thumpnail-desktop"}/>
            {/* <Thumpnail props={vehicles.props}/>  */}
          </div>
        </div>
        <div className="list-group-vehicle-item-content-right-ragged-desk">
          <div className="list-group-vehicle-item-content-right-ragged-desk-flex">
            <div className="list-group-vehicle-item-content-element-flexible" style={{fontWeight:"bold", paddingRight:"20px"}}>
              {vehicles.props.vehicleType}
            </div>
            <div className="list-group-vehicle-item-content-element-flexible">
              <div className="list-group-vehicle-item-content-element-row-container">
                <div className="list-group-vehicle-item-content-element-row">
                  {vehicles.props.ez}
                </div>
                <div className="list-group-vehicle-item-content-element-row">
                  {vehicles.props.km.toLocaleString('de')} KM
                </div>
                <div className="list-group-vehicle-item-content-element-row">
                  {vehicles.props.ps}
                </div>
              </div>
            </div>
            <div className="list-group-vehicle-item-content-element-flexible">
              <div className="list-group-vehicle-item-content-element-row-container">
                <div className="list-group-vehicle-item-content-element-row">   
                  {vehicles.props.fuelType}
                </div>
                <div className="list-group-vehicle-item-content-element-row">
                  {vehicles.props.gearBox}
                </div>
                <div className="list-group-vehicle-item-content-element-row">
                  {vehicles.props.hu}
                </div>
              </div>
            </div>
            <div className="list-group-vehicle-item-content-element-rightaligned" style={{textAlign:"right", fontSize:"14pt", justifyContent:"center", display:"flex", flexDirection:"column", paddingBottom:"30px"}}>
              {
                (() => {
                  if(vehicles.props.netGiven) {
                    return (
                      <div className="price">
                        <div style={{fontWeight: 'bold', paddingBottom:"10px"}}>{vehicles.props.grossPrice.toLocaleString("de-DE")} € (brutto) </div>
                        <div style={{fontSize:"12pt"}}> {vehicles.props.netPrice.toLocaleString("de-DE")} € (netto)</div>
                        <div style={{fontSize:"12pt"}}> {vehicles.props.tax} </div>
                      </div>
                    )
                  } else {
                    return (
                      <div className="price" style={{fontWeight: 'bold'}}>
                        {vehicles.props.grossPrice.toLocaleString("de-DE")} €
                      </div>
                    )
                  }
                })()
              }
            </div>
          </div>
          <div className="list-group-vehicle-item-fuel-line">
            <div className="list-group-vehicle-item-fuel-line-inner">
              {
                (() => {
                  if(vehicles.props.fuelConsumption === "no fuel consumption given") {
                    return ""
                  } else {
                    return vehicles.props.fuelConsumption
                  }
                })()  
              }
            </div>
            <div className="list-group-vehicle-item-fuel-line-right" style={{paddingRight:"5px"}}> <span className="NavigateToDetails" >ZU DEN DETAILS</span>   </div>
          </div>
        </div>
      </div>
    );
  }
};

export default VehiclelistContentline;