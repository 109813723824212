import React, { Component } from 'react';

 
class Header extends Component {
  render() {
    return (
      <div className="header">
        <div className="page-title"> 
         <img src="/images/logo.svg" className="logo-header" alt="Autohaus Kauschinger Logo"/> 
        </div>
      </div>
    );
  }}
// }
 
export default Header

